import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { RouteConstants } from 'src/constants/route';

import ComponentLoading from 'src/components/loading';

const ScreenHome = React.lazy(() => import('./home'));

const Router: React.FC = () => {
  return (
    <>
      <Routes>
        <Route
          path={`${RouteConstants.PATH_HOME}`}
          element={
            <Suspense fallback={<ComponentLoading />}>
              <ScreenHome />
            </Suspense>
          }
        />
        <Route path="*" element={<Navigate replace to={`${RouteConstants.PATH_HOME}`} />} />
      </Routes>
    </>
  );
};

export default Router;
