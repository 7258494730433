import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setDeviceLocation } from 'src/store/deviceLocation';

export const useDeviceLocation = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          dispatch(
            setDeviceLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            }),
          );
        },
        (error) => {
          throw error;
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        },
      );
    } catch (error) {
      console.error(error);
      dispatch(setDeviceLocation({ latitude: null, longitude: null }));
    }
  }, [dispatch]);
};
