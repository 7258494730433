import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';

import { CookiesConstants } from './constants/cookies';

import { RootState } from './store';
import { setInitialParamToken } from './store/initialParam';
import { setAppApiUserMe, setAppApiWeatherCurrent } from './store/appApi';

import { useInitialParam } from './hooks/useInitialParam';
import { useDeviceLocation } from './hooks/useDeviceLocation';
import { useAppApi } from 'src/hooks/useAppApi';

import Router from './screens';

const App: React.FC = () => {
  const dispatch = useDispatch();

  useInitialParam();
  useDeviceLocation();

  const token = useSelector((state: RootState) => state.initialParam.token);
  const telegramWebAppData = useSelector(
    (state: RootState) => state.initialParam.telegramWebAppData,
  );
  const deviceLocation = useSelector((state: RootState) => state.deviceLocation);

  const { appApiUserFromTelegramWebAppData, appApiUserMe, appApiWeatherCurrent } = useAppApi();

  useEffect(() => {
    if (!telegramWebAppData) {
      return;
    }
    appApiUserFromTelegramWebAppData({ webAppData: telegramWebAppData })
      .then((data) => {
        Cookies.set(CookiesConstants.TOKEN_KEY, data?.token || '', {
          expires: CookiesConstants.TOKEN_EXPIRES,
        });
        dispatch(setInitialParamToken(data?.token || ''));
      })
      .catch(() => {});
  }, [dispatch, telegramWebAppData, appApiUserFromTelegramWebAppData]);

  useEffect(() => {
    if (!token) {
      return;
    }
    appApiUserMe()
      .then((data) => {
        dispatch(setAppApiUserMe(data));
      })
      .catch(() => {});
  }, [dispatch, token, appApiUserMe]);

  useEffect(() => {
    if (!token) {
      return;
    }
    if (deviceLocation.latitude === null || deviceLocation.longitude === null) {
      return;
    }
    appApiWeatherCurrent({
      latitude: deviceLocation.latitude,
      longitude: deviceLocation.longitude,
    })
      .then((data) => {
        dispatch(setAppApiWeatherCurrent(data));
      })
      .catch(() => {});
  }, [dispatch, token, deviceLocation, appApiWeatherCurrent]);

  return (
    <>
      <div className="flex flex-col items-center h-full select-none">
        <div className="w-[500px] max-w-full h-full py-10 px-5">
          <Router />
        </div>
      </div>
    </>
  );
};

export default App;
