import { configureStore } from '@reduxjs/toolkit';

import initialParam from './initialParam';
import deviceLocation from './deviceLocation';
import appApi from './appApi';

export const store = configureStore({
  reducer: {
    initialParam,
    deviceLocation,
    appApi,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
