import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface DeviceLocationState {
  latitude: number | null;
  longitude: number | null;
}

const initialState: DeviceLocationState = {
  latitude: null,
  longitude: null,
};

export const deviceLocationSlice = createSlice({
  name: 'deviceLocation',
  initialState,
  reducers: {
    setDeviceLocation: (
      state,
      action: PayloadAction<{ latitude: number | null; longitude: number | null }>,
    ) => {
      state.latitude = action.payload.latitude;
      state.longitude = action.payload.longitude;
    },
  },
});

export const { setDeviceLocation } = deviceLocationSlice.actions;

const deviceLocationReducer = deviceLocationSlice.reducer;
export default deviceLocationReducer;
