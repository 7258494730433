import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { AppApiUserMeResponseDto, AppApiWeatherCurrentResponseDto } from 'src/types/appApi';

export interface AppApiState {
  userMe: AppApiUserMeResponseDto | null;
  weatherCurrent: AppApiWeatherCurrentResponseDto | null;
}

const initialState: AppApiState = {
  userMe: null,
  weatherCurrent: null,
};

export const appApiSlice = createSlice({
  name: 'appApi',
  initialState,
  reducers: {
    setAppApiUserMe: (state, action: PayloadAction<AppApiUserMeResponseDto | null>) => {
      state.userMe = action.payload;
    },
    setAppApiWeatherCurrent: (
      state,
      action: PayloadAction<AppApiWeatherCurrentResponseDto | null>,
    ) => {
      state.weatherCurrent = action.payload;
    },
  },
});

export const { setAppApiUserMe, setAppApiWeatherCurrent } = appApiSlice.actions;

const appApiReducer = appApiSlice.reducer;
export default appApiReducer;
